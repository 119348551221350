import React from "react"
import { StaticQuery, graphql, navigate } from "gatsby"
import styled from "@emotion/styled"
import moment from "moment"

import mq from "../../utils/mq"
import StoreContext from "../../context/StoreContext"

const Container = styled.div`
  position: sticky;
  top: 0;
  padding-top: calc(1.5 * ${props => props.theme.navbar.height});
  padding-bottom: 1rem;
  background-color: ${props => props.theme.colors.background};

  transition: transform 500ms;
  color: white;
  grid-area: cart;

  padding-left: ${props => props.theme.spacing.padding.content};
  padding-right: ${props => props.theme.spacing.padding.content};
  width: 100vw;

  box-shadow: 0px 2px 2px black;

  ${props => props.theme.breakpoints.tablet} {
    padding: 2.5rem 3rem;
    width: 300px;

    position: relative;
    top: unset;
    background-color: ${props => props.theme.colors.background};
  }
`

const Content = styled.div`
  position: sticky;
  top: 100px;
  left: 0;
  max-width: 100%;
  overflow-x: scroll;

  display: flex;
  ${props => props.theme.breakpoints.tablet} {
    flex-direction: column;
    overflow-x: hidden;
  }
`

const CategoryItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  flex: 1;
  white-space: nowrap;

  color: #d1d1d1;

  margin-right: 1rem;
  font-size: ${props => props.theme.typography.body};

  &::before {
    content: "";

    display: none;
    margin-right: 1rem;
    width: 10px;
    height: 10px;
    border: 1px solid white;
    border-radius: 50%;

    transition: background-color 400ms;
  }

  ${props => props.theme.breakpoints.tablet} {
    margin-right: 0;
    &::before {
      display: block;
    }
    ${props =>
      props.active &&
      `
      &::before {
        background-color: white;
      }
    `}

    &:hover {
      &::before {
        background-color: lightgray;
      }
    }
  }
`

class CategoryMenu extends React.Component {
  handleScrollTo = cat => {
    const element = document.getElementById(cat)
    window.scrollTo(0, element.offsetTop - 49)
  }

  render() {
    const { categories, activeSection } = this.props
    return (
      <Container>
        <Content>
          {categories.map(node => (
            <CategoryItem
              active={node.slug === activeSection}
              onClick={() => this.handleScrollTo(node.slug)}
              key={node.id}
            >
              {node.name}
            </CategoryItem>
          ))}
        </Content>
      </Container>
    )
  }
}

export default CategoryMenu
