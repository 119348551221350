import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"

import mq from "../../utils/mq"

import InterfaceContext from "../../context/InterfaceContext"
import StoreContext from "../../context/StoreContext"

import NavItem from "../ui/NavItem"
import Logo from "../../assets/logo-guru.png"

const CartNav = styled(NavItem)`
  align-items: center;
  i {
    margin-right: 1rem;
  }

  font-size: ${props => props.theme.typography.header};

  ${props => props.theme.breakpoints.desktop} {
    ${props => !props.isCheckout && "display: none;"}
  }
`

const NavBar = styled.div`
  position: fixed;
  right: 0;
  left: 0;

  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0) 100%
  );

  transition: transform 300ms;

  display: grid;

  grid-template-columns: 1fr 1fr;
  ${props => props.theme.breakpoints.desktop} {
    ${props => !props.isCheckout && "grid-template-columns: 1fr;"}
  }

  height: ${props => props.theme.navbar.height};
  z-index: 1000;
  background-color: transparent;
  color: white;

  padding: 0 1rem;
  padding-top: 10px;
`

const HeaderLogo = styled.img`
  max-height: 2.5rem;
`

const CurrencyOption = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;

  &::after {
    display: block;
    content: " ";
    border-bottom: 1px solid black;
    transform: ${props => (props.selected ? "scaleX(1)" : "scaleX(0)")};
    transition: transform 300ms;
  }
`

const ItemCount = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  .checkout {
    cursor: pointer;
    border-radius: 3px;
    padding: 0.5rem;
    background-color: ${props => props.theme.colors.secondary};
  }
`

class Header extends React.Component {
  state = {
    prevScrollpos: 0,
    showNav: true,
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    this.setState({
      prevScrollpos: window.pageYOffset,
    })
    window.addEventListener("scroll", this.handleScroll)
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state

    const currentScrollPos = window.pageYOffset
    const showNav = prevScrollpos > currentScrollPos

    this.setState({
      prevScrollpos: currentScrollPos,
      showNav,
    })
  }

  render() {
    const { siteTitle, productNavigation, sideNav, isCheckout } = this.props
    return (
      <header>
        <InterfaceContext.Consumer>
          {({ toggleCart }) => (
            <StoreContext.Consumer>
              {({ cart, removeLineItem }) => {
                const itemsInCart = cart.items.length

                return (
                  <div>
                    <NavBar fixed={true} isCheckout={isCheckout}>
                      <NavItem
                        justifyMobile="start"
                        justifySelf={isCheckout ? "start" : "center"}
                      >
                        <Link className="align-center" to="/">
                          <HeaderLogo src={Logo} />
                        </Link>
                      </NavItem>
                      <CartNav
                        isCheckout={isCheckout}
                        onClick={() => toggleCart()}
                        showOnMobile
                        justifySelf="end"
                      >
                        <ItemCount>
                          <i className="fal fa-shopping-bag" />
                          {itemsInCart > 0 && (
                            <div className="checkout">
                              {isCheckout ? "Order Summary" : "Checkout"}
                            </div>
                          )}
                        </ItemCount>
                      </CartNav>
                    </NavBar>
                  </div>
                )
              }}
            </StoreContext.Consumer>
          )}
        </InterfaceContext.Consumer>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
