import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import moment from "moment"
import { ThemeProvider } from "emotion-theming"
import { navigate } from "gatsby"

import StoreContext, { defaultStoreContext } from "../../context/StoreContext"
import InterfaceContext, {
  defaultInterfaceContext,
} from "../../context/InterfaceContext"
import Link from "../../utils/Link"
import mq from "../../utils/mq"

import CategoryMenu from "../CategoryMenu"
import Header from "../Header/TakeawayHeader"
import Footer from "../Footer"
import getData, { request } from "../../services/api"

import Theme from "../../Theme"
import "@fortawesome/fontawesome-pro/css/all.min.css"
import "./layout.css"

const TakeawayContainer = styled.div`
  display: flex;

  min-height: 100vh;
  background-color: ${props => props.theme.colors.background};
  padding-top: ${props => props.theme.navbar.height};
  main {
    flex-grow: 1;
  }
`

class TakeawayLayout extends React.Component {
  state = {
    interface: {
      ...defaultInterfaceContext,
      toggleCart: () => {
        this.setState(prevState => ({
          interface: {
            ...prevState.interface,
            isSideNavOpen: false,
            isCartOpen: !prevState.interface.isCartOpen,
          },
        }))
      },
    },
    store: {
      ...defaultStoreContext,
      putEmail: email => {
        return request("PUT", `/carts/email`, {
          email,
        }).then(({ data }) => {
          this.setCart(data.cart)
        })
      },
      putAddress: address => {
        return request("PUT", `/carts/address`, address).then(({ data }) => {
          this.setCart(data.cart)
        })
      },
      putDelivery: isDelivery => {
        if (isDelivery) {
          return request("PUT", "/carts/delivery").then(({ data }) => {
            this.setCart(data.cart)
          })
        }

        return request("PUT", "/carts/pickup").then(({ data }) => {
          this.setCart(data.cart)
        })
      },
      putTime: date => {
        return request("PUT", "/carts/time", {
          time: moment(date).format(),
        }).then(({ data }) => {
          this.setCart(data.cart)
        })
      },
      deleteNote: () => {
        return request("DELETE", "/carts/note").then(({ data }) => {
          this.setCart(data.cart)
        })
      },
      removeCode: code => {
        return request("DELETE", `/carts/coupon-code`).then(({ data }) => {
          this.setCart(data.cart)
        })
      },
      putCode: code => {
        return request("PUT", `/carts/coupon-code/${code}`).then(({ data }) => {
          this.setCart(data.cart)
        })
      },
      putNote: note => {
        return request("PUT", "/carts/note", {
          note,
        }).then(({ data }) => {
          this.setCart(data.cart)
        })
      },
      addLineItem: menuItemId => {
        return request("POST", "/carts/items", {
          menuItemId,
        }).then(({ data }) => {
          this.setCart(data.cart)
        })
      },
      removeLineItem: lineId => {
        return request("DELETE", `/carts/items/${lineId}`).then(({ data }) => {
          this.setCart(data.cart)
        })
      },
      updateLineItem: (lineId, quantity) => {},
    },
  }

  setCart(cart) {
    this.setState(state => ({
      store: {
        ...state.store,
        loading: false,
        cart: {
          total: cart.total,
          address: cart.address,
          couponCode: cart.couponCode,
          discount: cart.discount,
          email: cart.email,
          items: cart.items,
          note: cart.note,
          time: cart.time,
          isDelivery: cart.isDelivery,
          deliveryFee: cart.deliveryFee,
        },
      },
    }))
  }

  initializeCart() {
    this.setState(state => ({
      store: {
        ...state.store,
        loading: true,
      },
    }))
    return getData("/carts").then(({ data }) => {
      this.setCart(data.cart)
    })
  }

  componentDidMount() {
    this.initializeCart()

    this.setState(state => ({
      interface: {
        ...state.interface,
        isCheckout: this.props.isCheckout,
      },
    }))
  }

  render() {
    const { children, isCheckout } = this.props

    return (
      <ThemeProvider theme={Theme}>
        <InterfaceContext.Provider value={this.state.interface}>
          <StoreContext.Provider value={this.state.store}>
            <Header isCheckout={isCheckout} />
            <TakeawayContainer showCart={!!this.state.store.cart.pickupTime}>
              <main>{children}</main>
            </TakeawayContainer>
            <Footer />
          </StoreContext.Provider>
        </InterfaceContext.Provider>
      </ThemeProvider>
    )
  }
}

TakeawayLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TakeawayLayout
