import React from "react"
import styled from "@emotion/styled"
import { StripeProvider } from "react-stripe-elements"

import InterfaceContext from "../../context/InterfaceContext"
import StoreContext from "../../context/StoreContext"
import TakeawayLayout from "../../components/Layout/TakeawayLayout"
import CartDrawer from "../../components/CartDrawer"
import Checkout from "../../components/Checkout"

const STRIPE_API_KEY =
  process.env.GATSBY_STRIPE_API_KEY ||
  "pk_test_0r4VYT0eFfhBvZYtWxz4NIDS00Eo0lMNFM"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

class CheckoutPage extends React.Component {
  state = {
    stripe: null,
  }

  componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(STRIPE_API_KEY) })
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(STRIPE_API_KEY) })
      })
    }
  }

  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <TakeawayLayout isCheckout>
          <InterfaceContext.Consumer>
            {({ isCartOpen, toggleCart }) => (
              <StoreContext.Consumer>
                {({ cart, putAddress, putEmail }) => (
                  <Container>
                    <Checkout
                      cart={cart}
                      putAddress={putAddress}
                      putEmail={putEmail}
                    />
                    <CartDrawer
                      isCheckout
                      isOpen={isCartOpen}
                      toggleCart={toggleCart}
                    />
                  </Container>
                )}
              </StoreContext.Consumer>
            )}
          </InterfaceContext.Consumer>
        </TakeawayLayout>
      </StripeProvider>
    )
  }
}

export default CheckoutPage
