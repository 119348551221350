import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  cursor: pointer;
  display: flex;
  padding: 1rem;
  align-items: center;
`

const Checker = styled.div`
  border: 1px solid white;
  border-radius: 50%;
  padding: 5px;
  margin-right: 1rem;
  &::after {
    visibility: ${props => props.checked ? 'visible' : 'hidden'};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: block;
    content: "";
    background-color: white;
  }
`

const RadioBox = ({
  onClick,
  checked,
  children
}) => {
  return (
    <Container onClick={onClick}>
      <Checker checked={checked} />
      <div>
        {children}
      </div>
    </Container>
  )
}

export default RadioBox
