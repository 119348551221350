import React from "react"
import moment from "moment"
import styled from "@emotion/styled"
import { Elements } from "react-stripe-elements"
import { Link, navigate } from "gatsby"
import _ from "lodash"

import { request } from "../../services/api"
import { paymentDetailsAdded } from "../../services/analytics"

import InformationSection from "./InformationSection"
import PaymentSection from "./PaymentSection"
import Button from "../ui/Button"

const STRIPE_API_KEY =
  process.env.GATSBY_STRIPE_API_KEY ||
  "pk_test_0r4VYT0eFfhBvZYtWxz4NIDS00Eo0lMNFM"

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  min-height: 100vh;
  max-width: 600px;
  width: 100%;
  margin: auto;

  a {
    color: white;
  }
`

const CheckoutSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 3rem;
  border-bottom: 1px solid gray;

  .summary {
    div {
      margin-bottom: 1rem;
    }
  }
`

const SummarySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2rem 0;

  .section {
    margin-bottom: 1rem;
  }
`

class Checkout extends React.Component {
  state = {
    name: "",
    email: "",
    address1: "",
    city: "",
    postal: "",
    phone: "",
    currentStep: "information",
    paymentMethod: null,
  }

  componentDidMount() {
    this.setCurrentStep(this.props.cart)
  }

  componentWillReceiveProps(nextProps) {
    this.setCurrentStep(nextProps.cart)
  }

  setCurrentStep(cart) {
    if (cart.address) {
      this.setState({
        currentStep: "payment",
        name: cart.address.fullName,
        email: cart.email,
        phone: cart.address.phone,
        address1: cart.address.address1,
        city: cart.address.city,
        postal: cart.address.postal,
      })
    }

    if (cart.payment) {
      this.setState({
        currentStep: "complete",
      })
    }
  }

  handleCompleteOrder = () => {
    if (this.state.paymentMethod === "pay_at_pickup") {
      navigate(`/checkout/done?t=pap`)
    } else {
      const stripe = window.Stripe(STRIPE_API_KEY)
      stripe
        .handleCardPayment(this.state.intent, {
          payment_method: this.state.paymentMethod.id,
        })
        .then(({ paymentIntent }) => {
          if (paymentIntent.status === "succeeded") {
            navigate(`/checkout/done?t=cc&i=${paymentIntent.id}`)
          }
        })
    }
  }

  handlePaymentMethodSubmitted = method => {
    request("PUT", "/carts/payment", {
      type: method.type,
    }).then(() => {
      paymentDetailsAdded(this.props.cart)
      if (method.type === "credit_card") {
        this.setState({
          paymentMethod: method.paymentMethod,
          intent: method.intent,
          currentStep: "complete",
        })
      }

      if (method.type === "pay_at_pickup") {
        this.setState({
          paymentMethod: method.type,
          currentStep: "complete",
        })
      }
    })
  }

  handleFieldChange = e => {
    const element = e.target
    this.setState({
      [element.name]: element.value,
    })
  }

  handleEditInformation = () => {
    const { email, address } = this.props.cart
    this.setState({
      currentStep: "information",
      name: address.fullName,
      email,
      phone: address.phone,
      address1: address.address1,
      city: address.city,
      postal: address.postal,
    })
  }

  handleEditPayment = () => {
    this.setState({
      paymentMethod: null,
      currentStep: "payment",
    })
  }

  render() {
    let {
      paymentMethod,
      name,
      email,
      address1,
      city,
      postal,
      phone,
      currentStep,
    } = this.state
    const { cart, putAddress, putEmail } = this.props

    return (
      <CheckoutContainer>
        <Link to="takeaway">
          <i className="fal fa-arrow-left" /> Back
        </Link>
        <InformationSection
          active={currentStep === "information"}
          name={name}
          email={email}
          phone={phone}
          address1={address1}
          postal={postal}
          city={city}
          onValidateAddress={putAddress}
          onValidateEmail={putEmail}
          onFieldChange={this.handleFieldChange}
          onEdit={this.handleEditInformation}
        />
        <Elements>
          <PaymentSection
            name={name}
            isDelivery={cart.isDelivery}
            active={currentStep === "payment"}
            paymentMethod={this.state.paymentMethod}
            onPaymentMethodSubmitted={this.handlePaymentMethodSubmitted}
            onEdit={this.handleEditPayment}
          />
        </Elements>
        <CheckoutSection>
          <h2>3. Complete your order</h2>
          <div className="summary"></div>
          {this.state.paymentMethod && !_.isEmpty(cart.address) && (
            <Button onClick={this.handleCompleteOrder}>
              Complete DKK {cart.total.toFixed(2)}
            </Button>
          )}
        </CheckoutSection>
      </CheckoutContainer>
    )
  }
}

export default Checkout
