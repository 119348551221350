import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import moment from "moment"

import StoreContext from "../../context/StoreContext"
import mq from "../../utils/mq"
import Button from "../ui/Button"
import TimePicker from "../ui/TimePicker"
import Input from "../ui/Input"

const CheckoutButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  i {
    margin-left: 1rem;
  }

  margin-bottom: 2rem;
`

const Cart = styled.div`
  position: fixed ${props => props.isCheckout && "!important"};
  background-color: ${props => props.theme.colors.brand};
  transform: ${props => (props.isOpen ? "translateX(0)" : "translateX(100%)")};
  z-index: 2000;

  height: 100%;

  .show-on-mobile {
    font-size: 1.5rem;
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    hr {
      border-bottom: 1px solid gray;
      margin-bottom: 0.5rem;
    }
    .items {
      overflow: auto;
      margin-right: -2rem;
      padding-right: 2rem;
    }

    padding-bottom: 3rem;
  }

  .title {
    text-transform: uppercase;
    font-size: ${props => props.theme.typography.body};
    font-family: "Poppins", sans-serif;
  }

  top: 0;
  right: 0;
  transition: transform 500ms;
  color: white;

  padding: 1rem 3rem;
  width: 400px;

  ${props => props.theme.breakpoints.desktop} {
    ${props =>
      !props.isCheckout &&
      `
      background-color: ${props.theme.colors.background};
      transform: none;
      z-index: 0;
      position: relative;
      .show-on-mobile {
        display: none;
      }

      height: initial;
      .content {
        height: initial;
        position: sticky;
        top: 2.5rem;
      }
    `}
  }
`

const CartRow = styled.div`
  display: flex;
  justify-content: space-between;

  .row {
    display: flex;
  }
`

const CartItem = styled.div`
  display: flex;
  margin: 1rem 0;
`

const Remove = styled.div`
  cursor: pointer;
  ${props => props.marginRight && `margin-right: 0.5rem;`}
`

const DetailSection = styled.div`
  width: 100%;
`

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .price {
    color: gray;
    margin-top: 0.5rem;
  }
`

const CartAction = styled.div`
  cursor: pointer;
  margin-top: 1rem;
  text-decoration: underline;
`

const CouponCode = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  .error {
    color: ${props => props.theme.colors.danger};
    padding-bottom: 1rem;
    width: 100%;
  }
`

const Notes = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  font-size: ${props =>
    props.hasNote ? props.theme.typography.header : "initial"};
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid gray;

  > div {
    width: 100%;
  }

  ${Remove} {
    width: min-content;
  }

  ${Button} {
    padding: 0.5rem;
    width: max-content;
  }

  input {
    margin-top: 1rem;
    width: 100%;
    border-radius: 2px;
  }

  p {
    width: 100%;
    font-size: ${props => props.theme.typography.body};
    padding: 10px;
  }
`

class CartDrawer extends React.Component {
  state = {
    code: "",
    error: "",
    note: "",
    addCode: false,
    addNote: false,
  }

  handleAddCode = () => {
    this.setState(state => ({
      addCode: !state.addCode,
    }))
  }

  handleAddNote = () => {
    this.setState(state => ({
      addNote: !state.addNote,
    }))
  }

  handleFieldChange = e => {
    const element = e.target
    const name = element.name
    this.setState({
      error: "",
      [name]: element.value,
    })
  }

  handleCheckout = () => {
    if (this.props.onCheckout) {
      this.props.onCheckout()
    }
  }

  render() {
    const { isCheckout, isOpen, toggleCart } = this.props

    const now = new Date()
    const isClosed = now.getDay() === 0

    return (
      <StaticQuery
        query={graphql`
          {
            allContentfulMenuItem {
              edges {
                node {
                  id
                  name
                  price
                }
              }
            }
          }
        `}
        render={({ allContentfulMenuItem: itemStore }) => (
          <StoreContext.Consumer>
            {({
              loading,
              cart,
              putTime,
              putNote,
              putCode,
              removeCode,
              deleteNote,
              putDelivery,
              removeLineItem,
            }) => {
              return (
                <Cart isCheckout={isCheckout} isOpen={isOpen}>
                  <div className="content">
                    <div className="show-on-mobile">
                      <i
                        onClick={() => toggleCart()}
                        className="fal fa-times"
                      />
                    </div>
                    {isClosed ? (
                      <div>Sorry we are closed for today</div>
                    ) : (
                      <>
                        {!loading && (
                          <TimePicker
                            canEdit={!isCheckout}
                            isDelivery={cart.isDelivery}
                            selectedTime={cart.time}
                            onIsDelivery={putDelivery}
                            onTimeSelected={putTime}
                          />
                        )}
                        {!!cart.items.length && (
                          <>
                            {!isCheckout && (
                              <CheckoutButton onClick={this.handleCheckout}>
                                Continue to checkout
                                <i className="fal fa-long-arrow-right" />
                              </CheckoutButton>
                            )}
                            <div className="title">Your order</div>
                            <div className="items">
                              {cart.items.map(i => (
                                <CartItem key={i._id}>
                                  <DetailSection>
                                    <Details>
                                      <div>{i.menuItem.name}</div>
                                      <div className="price">
                                        DKK {i.menuItem.price.toFixed(2)}
                                      </div>
                                    </Details>
                                  </DetailSection>
                                  {!isCheckout && (
                                    <Remove
                                      onClick={() => removeLineItem(i._id)}
                                    >
                                      <i className="fal fa-times" />
                                    </Remove>
                                  )}
                                </CartItem>
                              ))}
                            </div>
                            <hr />
                            {cart.discount > 0 && (
                              <CartRow>
                                <div className="row">
                                  <Remove marginRight onClick={removeCode}>
                                    &#215;
                                  </Remove>
                                  Discount {cart.couponCode}
                                </div>
                                <div>-{cart.discount}%</div>
                              </CartRow>
                            )}
                            {cart.deliveryFee !== 0 && (
                              <CartRow>
                                <div>Delivery</div>
                                <div>DKK {cart.deliveryFee.toFixed(2)}</div>
                              </CartRow>
                            )}
                            <CartRow>
                              <div>Your total</div>
                              <div>
                                DKK {cart.total && cart.total.toFixed(2)}
                              </div>
                            </CartRow>
                            <Notes hasNote={!!cart.note}>
                              {cart.note ? (
                                <>
                                  Notes
                                  <Remove onClick={deleteNote}>
                                    <i className="fal fa-times" />
                                  </Remove>
                                  <p>{cart.note}</p>
                                </>
                              ) : (
                                <div>
                                  {!this.state.addNote ? (
                                    <CartAction>
                                      <div onClick={this.handleAddNote}>
                                        Add a note
                                      </div>
                                    </CartAction>
                                  ) : (
                                    <div>
                                      <div>Order note</div>
                                      <input
                                        type="text"
                                        name="note"
                                        value={this.state.note}
                                        onChange={this.handleFieldChange}
                                      />
                                      <Button
                                        onClick={() => {
                                          putNote(this.state.note)
                                          this.handleAddNote()
                                        }}
                                      >
                                        Save note
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              )}
                            </Notes>
                            {!cart.couponCode && (
                              <CouponCode>
                                {!this.state.addCode ? (
                                  <CartAction>
                                    <div onClick={this.handleAddCode}>
                                      Add a promo code
                                    </div>
                                  </CartAction>
                                ) : (
                                  <>
                                    <div className="error">
                                      {this.state.error}
                                    </div>

                                    <Input
                                      label="Coupon Code*"
                                      placeholder="Coupon Code"
                                      name="code"
                                      value={this.state.code}
                                      onChange={this.handleFieldChange}
                                    />

                                    <Button
                                      onClick={() => {
                                        putCode(this.state.code)
                                          .then(() => {
                                            this.handleAddCode()
                                          })
                                          .catch(err => {
                                            if (
                                              err.response &&
                                              err.response.status === 422
                                            ) {
                                              this.setState({
                                                error:
                                                  err.response.data.errors[0]
                                                    .detail,
                                              })
                                            }
                                          })
                                      }}
                                    >
                                      Submit
                                    </Button>
                                  </>
                                )}
                              </CouponCode>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Cart>
              )
            }}
          </StoreContext.Consumer>
        )}
      />
    )
  }
}

export default CartDrawer
