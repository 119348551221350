import moment from "moment"

const OPEN_HOUR = 17
const OPEN_MINUTE = 0
const CLOSE_HOUR = 21
const CLOSE_MINUTE = 0
const TIME_SLOT_FREQ = 15

export const formatDay = date => {
  if (!date) return
  date = new Date(date)
  const now = new Date()
  if (date.getDate() === now.getDate()) {
    return "Today"
  }

  if (date.getDate() === now.getDate() + 1) {
    return "Tomorrow"
  }

  return moment(date).format("dddd")
}

export const getTimeSlots = fromTime => {
  const now = new Date()
  const today = now.getDate()

  if (fromTime) {
    if (fromTime.getDate() === today) {
      fromTime = now
    } else {
      fromTime.setHours(1)
      fromTime.setMinutes(0)
    }
  } else {
    fromTime = now
  }

  const fromDay = fromTime.getDate()

  const closeTime = new Date()
  closeTime.setDate(fromDay)
  closeTime.setHours(CLOSE_HOUR)
  closeTime.setMinutes(CLOSE_MINUTE)
  closeTime.setSeconds(0)

  const openTime = new Date()
  openTime.setDate(fromDay)
  openTime.setHours(OPEN_HOUR)
  openTime.setMinutes(OPEN_MINUTE)
  openTime.setSeconds(0)

  const timer = new Date(fromTime)
  timer.setSeconds(0)
  timer.setMinutes(
    Math.ceil(timer.getMinutes() / TIME_SLOT_FREQ) * TIME_SLOT_FREQ
  )

  if (timer.getTime() < openTime.getTime()) {
    timer.setHours(OPEN_HOUR)
    timer.setMinutes(OPEN_MINUTE)
  }

  const slots = []

  while (timer.getTime() < closeTime.getTime()) {
    slots.push(new Date(timer))
    timer.setMinutes(timer.getMinutes() + TIME_SLOT_FREQ)
  }

  return slots
}

export const getDays = () => {
  const time = new Date()
  let times = []

  time.setHours(1)
  time.setMinutes(0)
  time.setSeconds(0)

  for (let i = 0; i < 5; i++) {
    const newTime = new Date(time)
    const slots = getTimeSlots(newTime)

    if (slots.length) {
      times.push(newTime)
    }

    time.setDate(time.getDate() + 1)
  }

  return times
}

export const getFirstAvailable = (fromDate, isDelivery) => {
  fromDate = new Date(fromDate)
  const now = new Date()

  if (fromDate.getDate() !== now.getDate() || now.getHours() < OPEN_HOUR) {
    now.setDate(fromDate.getDate())
    now.setHours(OPEN_HOUR)
    now.setMinutes(OPEN_MINUTE)
    now.setSeconds(0)
  }

  let times = getTimeSlots(fromDate)

  let minDiff = isDelivery ? 50 : 30
  let earliest = times.find(t => moment(t).diff(now, "minutes") > minDiff)

  if (!earliest) {
    fromDate.setDate(fromDate.getDate() + 1)
    times = getTimeSlots(fromDate)
    earliest = times.find(t => moment(t).diff(now, "minutes") > minDiff)
  }

  return earliest
}
