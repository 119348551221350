import React from "react"
import styled from "@emotion/styled"

import Input from "../ui/Input"
import Button from "../ui/Button"

const Error = styled.div`
  color: ${props => props.theme.colors.danger};
  &:last-of-type {
    margin-bottom: 2rem;
  }
`

const InformationDone = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .section {
    margin-right: 3rem;
  }
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const CheckoutSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  color: white;
  padding: 3rem;
  border-bottom: 1px solid gray;
`

const FormRow = styled.div`
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 400px;
`

class InformationSection extends React.Component {
  state = {
    validationErrors: [],
  }

  handleSubmitInformation = (address, email) => {
    const { onValidateEmail, onValidateAddress, onSubmit } = this.props

    this.setState({
      validationErrors: [],
    })

    return Promise.all([
      onValidateEmail(this.props.email),
      onValidateAddress({
        fullName: this.props.name,
        address1: this.props.address1,
        city: this.props.city,
        postal: this.props.postal,
        phone: this.props.phone,
      }),
    ]).catch(({ response }) => {
      if (response.status === 422) {
        this.setState(state => {
          const { errors } = response.data
          const validationErrors = state.validationErrors.concat(errors)
          return {
            validationErrors,
          }
        })
      }
    })
  }

  render() {
    const {
      active,
      name,
      email,
      phone,
      address1,
      city,
      postal,
      onFieldChange,
      onEdit,
    } = this.props

    return (
      <CheckoutSection>
        <h2>1. Your Information</h2>
        {this.state.validationErrors.map(v => (
          <Error>
            {v.pointer.split("/")[2]} {v.detail}
          </Error>
        ))}
        {active ? (
          <Form>
            <FormRow>
              <Input
                label="Name*"
                name="name"
                value={name}
                placeholder="Name"
                onChange={onFieldChange}
              />
            </FormRow>
            <FormRow>
              <Input
                label="E-mail*"
                name="email"
                value={email}
                placeholder="E-mail"
                onChange={onFieldChange}
              />
            </FormRow>
            <FormRow>
              <Input
                label="Phone number*"
                name="phone"
                value={phone}
                placeholder="Phone number"
                onChange={onFieldChange}
              />
            </FormRow>
            <FormRow>
              <Input
                label="Street Name & no.*"
                name="address1"
                value={address1}
                placeholder="Street Name & no."
                onChange={onFieldChange}
              />
            </FormRow>
            <FormRow>
              <Input
                label="City*"
                name="city"
                value={city}
                placeholder="City"
                onChange={onFieldChange}
              />
              <Input
                label="Postal Code*"
                name="postal"
                value={postal}
                placeholder="Postal Code"
                onChange={onFieldChange}
              />
            </FormRow>
            <Button onClick={this.handleSubmitInformation} outline>
              Next
            </Button>
          </Form>
        ) : (
          <InformationDone>
            <div className="section">
              <strong>Details</strong>
              <div>{name}</div>
              <div>{email}</div>
              <div>{phone}</div>
              <div>{address1}</div>
              <div>
                {city}, {postal}
              </div>
            </div>
            <div>
              <Button padding=".3rem 1rem" onClick={onEdit}>
                Edit
              </Button>
            </div>
          </InformationDone>
        )}
      </CheckoutSection>
    )
  }
}

export default InformationSection
