import React from "react"
import styled from "@emotion/styled"
import moment from "moment"

import {
  getDays,
  getTimeSlots,
  getFirstAvailable,
  formatDay,
} from "../../utils/time-slots"

const SpacedI = styled.i`
  color: grey;
  margin-left: 0.5rem;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  font-size: 18px;
`

const TypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  background-color: ${props => props.theme.colors.brand};
  padding: 0.5rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
`
const TypeOption = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 3px;
  transition: background-color 300ms;

  background-color: ${props =>
    props.isSelected ? props.theme.colors.secondary : "transparent"};
`

const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 1rem;
`

const Dropdown = styled.div`
  position: absolute;

  display: ${props => (props.visible ? "block" : "none")};

  color: white;
  background-color: ${props => props.theme.colors.brand};
  top: calc(100% + 1px);
  left: 0;
  max-height: 50vh;
  width: 100%;
  z-index: 1000;

  font-size: 14px;

  overflow: scroll;
  border: 1px solid ${props => props.theme.colors.secondary};
  border-top: none;

  .option {
    cursor: pointer;
    padding: 1rem;
    text-align: center;

    &:hover {
      background-color: ${props => props.theme.colors.background};
    }
  }
`

const Button = styled.div`
  position: relative;
  display: flex;
  color: ${props => (props.light ? "lightgray" : props.theme.colors.secondary)};
  ${props => !props.noMargin && "margin-left: 1rem;"}
  min-width: 7rem;

  justify-content: center;
  align-items: center;

  border: 1px solid grey;
  border-radius: 2px;
  padding: 0 5px;

  border-color: ${props => (props.light ? "lightgray" : "gray")} ${SpacedI} {
    color: ${props => (props.light ? "lightgray" : "gray")};
  }

  ${Dropdown} {
    border-color: ${props => (props.light ? "lightgray" : "gray")};
  }
`

class TimePicker extends React.Component {
  state = {
    deliveryDropdownActive: false,
    dropdownActive: false,
    times: [],
    dates: [],
    selectedDate: null,
    selectedTime: null,
    isDelivery: false,
  }

  componentDidMount() {
    const selectedTime =
      (this.props.selectedTime && new Date(this.props.selectedTime)) ||
      new Date()

    const dates = getDays()
    const times = getTimeSlots(selectedTime)
    const canPickup = !!getFirstAvailable(selectedTime, false)
    const canDeliver = !!getFirstAvailable(selectedTime, true)

    this.setState({
      isDelivery: this.props.isDelivery,
      selectedDate: selectedTime,
      canPickup,
      canDeliver,
      times,
      dates,
    })
  }

  handleDateSelected = date => {
    const canPickup = !!getFirstAvailable(selectedTime, false)
    const canDeliver = !!getFirstAvailable(selectedTime, true)
    this.setState({
      selectedDate: date,
      times: getTimeSlots(date),
      canPickup,
      canDeliver,
    })
  }

  handleTimeSelected = date => {
    this.setState({
      selectedTime: date,
    })
  }

  async componentDidUpdate(prevProps, prevState) {
    let { selectedTime } = this.props
    let { selectedDate, isDelivery } = this.state

    if (selectedTime) {
      selectedTime = new Date(selectedTime)
    }

    let newTime = selectedTime

    if (this.state.selectedTime !== prevState.selectedTime) {
      newTime = this.state.selectedTime
    }

    if (
      this.props.canEdit &&
      (selectedDate !== prevState.selectedDate ||
        this.state.selectedTime !== prevState.selectedTime ||
        isDelivery !== prevState.isDelivery)
    ) {
      if (isDelivery !== this.props.isDelivery) {
        if (this.props.onIsDelivery) {
          await this.props.onIsDelivery(isDelivery)
        }
      }

      if (!newTime || isDelivery !== prevState.isDelivery) {
        newTime = getFirstAvailable(selectedDate, isDelivery)
      }

      const now = new Date()
      let date = new Date()

      // Set day
      date.setDate(selectedDate.getDate())

      // Set time of day
      date.setHours(newTime.getHours())
      date.setMinutes(newTime.getMinutes())
      date.setSeconds(0)

      if (now.getTime() > date.getTime()) {
        date = getFirstAvailable(selectedDate, isDelivery)
      }

      if (this.props.onTimeSelected) {
        this.props.onTimeSelected(date)
      }
    }
  }

  handleIsDelivery = isDelivery => {
    this.setState({
      isDelivery,
    })
  }

  toggleDate = () => {
    this.setState(prevState => ({
      dateDropdownActive: !prevState.dateDropdownActive,
    }))
  }

  toggleDeliveryDropdown = () => {
    this.setState(prevState => ({
      deliveryDropdownActive: !prevState.deliveryDropdownActive,
    }))
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownActive: !prevState.dropdownActive,
    }))
  }

  render() {
    const now = new Date()
    const { isDelivery, selectedTime } = this.props
    const {
      dates,
      times,
      dropdownActive,
      deliveryDropdownActive,
      dateDropdownActive,
    } = this.state
    const timeIndex = isDelivery ? 3 : 1
    const earliest = selectedTime

    const diff = moment(earliest).diff(now, "minutes")

    const specifier = isDelivery ? "Deliver" : "Pick up"
    const propWord = diff > 50 ? "at" : "in"
    const timeSpec =
      diff > 50 ? moment(earliest).format("HH:mm") : `${diff} minutes`

    if (!this.props.canEdit) {
      return (
        <Container>
          {specifier} {formatDay(new Date(selectedTime))} {propWord} {timeSpec}
        </Container>
      )
    }

    return (
      <Container>
        <TypeContainer>
          {this.state.canPickup && (
            <TypeOption
              isSelected={!isDelivery}
              onClick={() => this.handleIsDelivery(false)}
              className="option"
            >
              Pick up
            </TypeOption>
          )}
          {this.state.canDeliver && (
            <TypeOption
              isSelected={isDelivery}
              onClick={() => this.handleIsDelivery(true)}
              className="option"
            >
              Delivery
            </TypeOption>
          )}
        </TypeContainer>
        <DateContainer>
          <Button noMargin light onClick={this.toggleDate}>
            {earliest && (
              <>
                {formatDay(earliest)}
                <SpacedI className="fal fa-caret-down" />
              </>
            )}
            <Dropdown visible={dateDropdownActive}>
              {this.state.dates.map(d => (
                <div
                  key={d.getTime()}
                  onClick={() => this.handleDateSelected(d)}
                  className="option"
                >
                  {formatDay(d)}
                </div>
              ))}
            </Dropdown>
          </Button>

          {propWord}

          <Button noMargin light onClick={this.toggleDropdown}>
            {earliest && (
              <>
                {timeSpec}
                <SpacedI className="fal fa-caret-down" />
              </>
            )}
            <Dropdown visible={dropdownActive}>
              {this.state.times.map(
                (t, index) =>
                  index >= timeIndex && (
                    <div
                      onClick={() => this.handleTimeSelected(t)}
                      className="option"
                      key={`${t.getHours()}-${t.getMinutes()}`}
                    >
                      {moment(t).format("HH:mm")}
                    </div>
                  )
              )}
            </Dropdown>
          </Button>
        </DateContainer>
      </Container>
    )
  }
}

export default TimePicker
