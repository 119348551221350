import React from "react"
import styled from "@emotion/styled"
import { injectStripe } from "react-stripe-elements"

import { request } from "../../services/api"
import PaymentForm from "../Checkout/PaymentForm"
import RadioBox from "../ui/RadioBox"
import Button from "../ui/Button"
import LoadingSpinner from "../ui/LoadingSpinner"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  width: 100%;
`

const PayAtPickupContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 2rem;
`

const PaymentDone = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const CheckoutSection = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  padding: 3rem;
  border-bottom: 1px solid gray;
  align-items: center;
  width: 100%;
`

const RadioContainer = styled.div`
  display: grid;
  margin: auto;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 0px;
  & > div:first-of-type {
    border-left: 1px solid white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > div:last-of-type {
    border: 1px solid white;
    border-radius: 2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

class PaymentSection extends React.Component {
  state = {
    intent: null,
    paymentType: "credit_card",
    paymentMethod: null,
  }

  componentDidMount() {
    request("POST", "/carts/payment-intent").then(({ data }) => {
      this.setState({
        intent: data.intent.client_secret,
      })
    })
  }

  handleRadioClick = val => {
    this.setState({
      paymentType: val,
    })
  }

  handleSubmit = () => {
    const { name } = this.props
    const { paymentType } = this.state

    if (paymentType === "credit_card") {
      this.props.stripe
        .createPaymentMethod("card", {
          billing_details: { name },
        })
        .then(({ paymentMethod }) => {
          this.props.onPaymentMethodSubmitted({
            type: paymentType,
            paymentMethod,
            intent: this.state.intent,
          })
        })
    } else {
      this.props.onPaymentMethodSubmitted({
        type: paymentType,
      })
    }
  }

  render() {
    const { active, isDelivery, paymentMethod, onEdit } = this.props

    const { paymentType, intent } = this.state

    return (
      <CheckoutSection>
        <h2>2. Payment Method</h2>
        {!paymentMethod && active ? (
          <Container>
            {/*!isDelivery && (
              <RadioContainer>
                <RadioBox
                  onClick={() => this.handleRadioClick("credit_card")}
                  checked={this.state.paymentType === "credit_card"}
                >
                  Pay with Credit Card
                </RadioBox>
                <RadioBox
                  onClick={() => this.handleRadioClick("pay_at_pickup")}
                  checked={this.state.paymentType === "pay_at_pickup"}
                >
                  Pay in Restaurant
                </RadioBox>
              </RadioContainer>
            )*/}
            {paymentType === "credit_card" &&
              (intent ? (
                <PaymentForm onSubmit={this.handleSubmit} />
              ) : (
                <LoadingSpinner />
              ))}
            {paymentType === "pay_at_pickup" && (
              <PayAtPickupContainer>
                <Button outline onClick={this.handleSubmit}>
                  Next
                </Button>
              </PayAtPickupContainer>
            )}
          </Container>
        ) : (
          <PaymentDone>
            {!active && paymentMethod && (
              <>
                <div>
                  {paymentMethod === "pay_at_pickup" ? (
                    <div>Pay at the restaurant</div>
                  ) : (
                    <div>
                      {`
                        ${paymentMethod.card.brand}
                        ending in
                        ${paymentMethod.card.last4}
                      `}
                    </div>
                  )}
                </div>
                <div>
                  <Button padding=".3rem 1rem" onClick={onEdit}>
                    Edit
                  </Button>
                </div>
              </>
            )}
          </PaymentDone>
        )}
      </CheckoutSection>
    )
  }
}

export default injectStripe(PaymentSection)
