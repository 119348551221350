import React from "react"
import styled from "@emotion/styled"
import { CardElement } from "react-stripe-elements"

const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 500px;
  margin-top: 2rem;

  .StripeElement {
    box-sizing: border-box;

    height: 40px;

    padding: 10px 12px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  button {
    cursor: pointer;
    padding: 1rem;
    border-radius: 2px;
    width: max-content;
    margin-top: 1.5rem;
    border: 1px solid white;
    background: none;
    color: white;
    &:hover {
      color: black;
      background: white;
    }
  }
`

const PaymentForm = ({ onSubmit }) => (
  <Container>
    <form
      onSubmit={e => {
        e.preventDefault()
        return onSubmit()
      }}
    >
      <CardElement />
      <button>Next</button>
    </form>
  </Container>
)

export default PaymentForm
