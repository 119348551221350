import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const InputContainer = styled.label`
  position: relative;
  width: 100%;
  margin-bottom: .8rem;
  //transform: rotateX(45deg) rotateY(-10deg) rotate(25deg) scale(2)

  .label {
    position: absolute;
    top: 16px;
    left: 0;
    font-size: 16px;
    color: white;
    font-weight: 500;
    transform-origin: 0 0;
    transition: all 300ms ease;
  }

  .border {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background white;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all 300ms ease;
  }

  input {
    -webkit-appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 12px 0;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #C8CCD4;
    background: none !important;
    border-radius: 0;
    color: white;
    transition: all 300ms ease;

    &:hover {
      background: rgba(#223254,.03);
    }

    &:not(:placeholder-shown) {
      & + span {
        color white;
        transform: translateY(-26px) scale(.75);
      }
    }

    &:focus {
      background: none !important;
      outline: none;
      & + span {
        color white;
        transform: translateY(-26px) scale(.75);
        & + .border {
          transform: scaleX(1);
        }
      }
    
  }
`

const Input = ({
  value,
  label,
  name,
  placeholder,
  onChange
}) => {
  return (
    <InputContainer>
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
      />
      <span className="label">{label}</span>
      <span className="border"></span>
    </InputContainer>
  )
}

Input.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Input
